
import { computed, defineComponent, ref } from "vue";
import Jumbotorn from "@/components/common/Jumbotron.vue";
import { RouterName } from "@/models/common";

export default defineComponent({
  name: "SecondOpinion Intro",
  props: {
    linkPath: String,
    isImage: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const linkTo = computed(() => {
      return props.linkPath
        ? { name: props.linkPath }
        : { name: RouterName.SECOND_OPINION_REQUEST_NEW };
    });
    const isMobileShow = ref(props.isImage);
    return { linkTo, isMobileShow };
  },
  components: {
    Jumbotorn,
  },
});
