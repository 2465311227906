
import { defineComponent } from "vue";

export default defineComponent({
  name: "Jumbotron",
  setup() {
    return {};
  },
  components: {},
});
