
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { RouterName } from "@/models/common";
import OnlineCareIntro from "@/components/onlineCare/Intro.vue";
import SecondOpinionIntro from "@/components/secondOpinion/Intro.vue";

export default defineComponent({
  name: "Home",
  setup() {
    const store = useStore();
    const { t, locale } = useI18n({ useScope: "global" });

    const goSecondOpinion = () => {
      router.push({ name: RouterName.SECOND_OPINION });
    };
    const goOnlineCare = () => {
      router.push({ name: RouterName.ONLINE_CARE });
    };

    return {
      store,
      t,
      locale,
      goSecondOpinion,
      goOnlineCare,
      RouterName,
    };
  },
  components: {
    OnlineCareIntro,
    SecondOpinionIntro,
  },
});
